export const data = [{
    "id" : 1, 
    "project": "CIVIL WORK / DRILLING LOCATION, KAMPANGPHET",
    "employer": "PAE ( Thailand ) Co, Ltd",
}, {
    "id" : 2, 
    "project": "PHITSANULOKE AIR BASE CONSTRUCTION",
    "employer": "ROYAL THAI AIRFORCE",
}, {
    "id" : 3, 
    "project": "LAND DEVELOPMENT AT RANONG AND PANGNA",
    "employer": "DEPT PUBLIC WELFARE",
},{
    "id" : 4, 
    "project": "EARTH DAM IN UDON THANI & ROIED",
    "employer": "ROYAL IRRIGATION DEPT",
}, {
    "id" : 5, 
    "project": "RESERVOIR IN SI - CHANG ISLAND, SRIRACHAR",
    "employer": "ROYAL IRRIGATION DEPT",
}, {
    "id" : 6, 
    "project": "ROAD CONSTRUCTION AND BUILDING",
    "employer": "LAND DEVELOPMENT OFFICE, LOPBURI",
}, {
    "id" : 7, 
    "project": "ROJANA INDUSTRIAL PARK PHASE IT LOT A",
    "employer": "HAZAMA CORP",
}, {
    "id" : 8, 
    "project": "SRI - RACHA INTERNATIONAL GOLF CLUB",
    "employer": "HAZAMA CORP",
}, {
    "id" : 9, 
    "project": "LAKEWOOD CAR PARK PHASE II LOT A",
    "employer": "HAZAMA CORP",
}, {
    "id" : 10, 
    "project": "ENTRANCE ROAD & CONTAINER YARD AT LAD KRABANG",
    "employer": "HAZAMA CORP",
}, {
    "id" : 11, 
    "project": "DON MUANG TOLLWAY PROJECT",
    "employer": "DYWIDAG CONSORTIUM",
}, {
    "id" : 12, 
    "project": "INCHCAPE CENTRAL WARE HOUSE ( BANGPAIN )",
    "employer": "CHRISTIANI & NIELSEN",
}, {
    "id" : 13, 
    "project": "CONSTRUCTION OF AT - GRADE ROAD AFTER DEMOLITION OF EXISTING",
    "employer": "DYWIDAG CONSORTIUM",
}, {
    "id" : 14, 
    "project": "LAM TA KHONG PROJECT",
    "employer": "VIANNI THAI CONS",
}, {
    "id" : 15, 
    "project": "MINN PLACE PROJECT",
    "employer": "MINTHONG CO,LTD",
}, {
    "id" : 16, 
    "project": "PEDESTRIAN BRIDGE",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 17, 
    "project": "DON MUANG TOLLWAY NORTHERN EXTENSION PROJECT",
    "employer": "DYWIDAG CONSORTIUM",
}, {
    "id" : 18, 
    "project": "WATER GATE & PUMOING STATION AT KLONG CHIANG RAK MAI",
    "employer": "ROYAL IRRIGATION DEPT",
}, {
    "id" : 19, 
    "project": "HIGHWAY BRIDGE AT NAKORNNAYOK",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 20, 
    "project": "ROAD CONSTRUCTION AT AYUDHAYA",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 21, 
    "project": "WATER GATE KLONG KOR PING",
    "employer": "ROYAL IRRIGATION DEPT",
}, {
    "id" : 22, 
    "project": "ROAD CONSTRUCTION KLONG LUANG CARGO TERMINAL",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 23, 
    "project": "ROAD CONSTRUCTION PHUKET",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 24, 
    "project": "WATER GATE & PUMOING STATION KLONG KUN NUEA",
    "employer": "ROYAL IRRIGATION DEPT",
}, {
    "id" : 25, 
    "project": "ROAD REPAIR VIPHA VADEE RUNGSIT",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 26, 
    "project": "ROAD CONSTRUCTION VIPHAVADEE RUNGSIT - SAMYEKKRASET",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 27, 
    "project": "ROAD CONSTRUCTION RANGSIT - BANGPRAIN",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 28, 
    "project": "ROAD CONSTRUCTION BANGKOK AND PHOONPHON",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 29, 
    "project": "ROAD CONSTRUCTION PHUKET - AOU MA KAM",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 30, 
    "project": "RETAINING WALL AT THA-KLANG CANAL",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 31, 
    "project": "RETAINING WALL AT BANG-YAI CANAL",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 32, 
    "project": "DEVELOPMENT LANDSCAPE AT BANG-YAI CANAL",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 33, 
    "project": "DEVELOPMENT LANDSCAPE AT KING RAMA 9 TH ‘S GARDEN",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 34, 
    "project": "CITY PLAN’S MAIN ROAD",
    "employer": "PATONG MUNICIPALITY",
}, {
    "id" : 35, 
    "project": "KATU MUNICIPALITY’S BUILDING",
    "employer": "KATU MUNICIPALITY",
}, {
    "id" : 36, 
    "project": "RETAINING WALL AND BOX CULVERT AT KARAON",
    "employer": "KARON MUNICIPALITY",
}, {
    "id" : 37, 
    "project": "CONCRETE ROAD AND FOOT PATH AT KARON",
    "employer": "KARON MUNICIPALITY",
}, {
    "id" : 38, 
    "project": "CHEANG TALE MUNICIPALITY’S BUILDING",
    "employer": "CHERNG TALAY MUNICIPALITY",
}, {
    "id" : 39, 
    "project": "KHOA-KHAD’S VIEW POINT",
    "employer": "VICHIT PHUKET",
}, {
    "id" : 40, 
    "project": "MAIN ROAD ,COLD WATER, DUCT BANK ETC",
    "employer": "ROYAL PHUKET MARINA",
}, {
    "id" : 41, 
    "project": "MAIN ROAD, GUTTER, AND ELECTRIC SYSTEM DEVELOPMENT AND NIGHT SAFARI",
    "employer": "DUSIT ZOO",
}, {
    "id" : 42, 
    "project": "INFRASTRUCTURE CIVIL WORKS, ROADS WORK AND TIMBER CRIB AT MALAIWANA PARK",
    "employer": "MALAIWANA PARK DEVELOPEMENT",
}, {
    "id" : 43, 
    "project": "MARINA BASIN CONSTRUCTION WORKS ETC",
    "employer": "TGR YATCH CLUB AND MARINA CO.,LTD.",
}, {
    "id" : 44, 
    "project": "PANG NGA GATEWAY",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 45, 
    "project": "ROAD CONSTRUCTION AT BAAN TEENKHOA - KATU",
    "employer": "DEPARTMENT OF RURAL ROADS",
}, {
    "id" : 46, 
    "project": "KLONG BANG YAI DAM PHUKET",
    "employer": "PHUKET MUNICIPALITY",
},{
    "id" : 47, 
    "project": "ROYAL PHUKET MARINA MAIN ROAD",
    "employer": "HERITAGE ASSET CO,LTD",
},{
    "id" : 48, 
    "project": "PAK BANG DAM AND SIDEWALK",
    "employer": "KARON MUNICIPALITY",
}, {
    "id" : 49, 
    "project": "KARON ROAD RENOVATION",
    "employer": "KARON MUNICIPALITY",
}, {
    "id" : 50, 
    "project": "KLONG BANG YAI LANDSCAPE WORKS",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 51, 
    "project": "WATER STORAGE ( KHUM NAM SUAN LUANG )",
    "employer": "PHUKET IRRIGATION DEPT",
}, {
    "id" : 52, 
    "project": "IRRIGATION SYSTEM ( BAN LAM LANG )",
    "employer": "PHUKET IRRIGATION DEPT",
}, {
    "id" : 53, 
    "project": "SURIN – RAWAI ROAD CONSTRUCTION",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 54, 
    "project": "ROAD CONSTRUCTION H N 4027 ( BANGRONG – AO POR )",
    "employer": "DEPARTMENT OF RURAL ROADS",
}, {
    "id" : 55, 
    "project": "TA CHAT CHAI CHECK POINT",
    "employer": "SCG CO,LTD",
}, {
    "id" : 56, 
    "project": "SAMSARA CONCRETE ROAD AND V GUTTER",
    "employer": "SAMSARA PROJECT PHUKET",
},{
    "id" : 57, 
    "project": "WATER PUMP STATION YAOWARAJ ROAD PHUKET",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 58, 
    "project": "ROAD CONSTRUCTION BANG TON KAO PATONG",
    "employer": "PATONG MUNICIPALITY",
}, {
    "id" : 59, 
    "project": "BOAT LAGOON PIER",
    "employer": "BOAT PATTANA CO,LTD",
}, {
    "id" : 60, 
    "project": "DAM AND DRAINAGE SYSTEM AT BAN KATA",
    "employer": "KARON MUNICIPALITY",
}, {
    "id" : 61, 
    "project": "ROAD REPAIR AT SARASIN BRIDGE",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 62, 
    "project": "ROAD REPAIR AT TARUA – HAAD SURIN",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 63, 
    "project": "ROAD CONSTRUCTION AT NEW SARASIN BRIDGE",
    "employer": "ITALIAN THAI DEVELOPMENT PCL",
}, {
    "id" : 64, 
    "project": "DRAINAGE SYSTEM AND SIDEWALK AT TALANG ( TWO HERINESS MONUMENT)",
    "employer": "DISTRICT OF TALANG",
}, {
    "id" : 65, 
    "project": "MINERAL MUSEUN AT KATU SUBDISTRICT",
    "employer": "KATU MUNICIPALITY",
}, {
    "id" : 66, 
    "project": "ROAD CONSTRUCTION KLONG LUM LANG",
    "employer": "DEPARTMENT OF RURAL ROADS",
}, {
    "id" : 67, 
    "project": "WATER CONTROL SYSTEM AT RATJAPHAT PHUKET UNIVERSITY",
    "employer": "DEPARTMENT OF ROYAL IRRIGATION",
}, {
    "id" : 68, 
    "project": "MINICIPAL RESERVOIR CONSTRUCTION",
    "employer": "DEPARTMENT OF ROYAL IRRIGATION",
}, {
    "id" : 69, 
    "project": "WATER TANK AT TAI MUANG",
    "employer": "WATER SUPPLIES KHET 4",
}, {
    "id" : 70, 
    "project": "FLOOD PROTECTION CANAL AT CHALONG",
    "employer": "O B J PHUKET",
}, {
    "id" : 71, 
    "project": "DRAINAGE CANAL AND PARKING LOT",
    "employer": "RAWAI MUNICIPALITY",
}, {
    "id" : 72, 
    "project": "ROAD WORK BOAT PLAZA PHASE 1 AND 2",
    "employer": "BOAT PATTANA CO,LTD",
}, {
    "id" : 73, 
    "project": "KLONG NANG YON DAM AT KURABURI DISTRICT, PHANG NYA",
    "employer": "DEPARTMENT OF ROYAL IRRIGATION",
}, {
    "id" : 74, 
    "project": "ROAD CONSTRUCTION AT PHANG-MUANG SAI KOR ROAD",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 75, 
    "project": "ROAD AND PARKING LOT CONSTRUCTION AT SIAMNIRAMIT PHUKET",
    "employer": "POWER LINE ENGINEERING PCL",
}, {
    "id" : 76, 
    "project": "ROAD CONSTRUCTION AT HIGHWAY NO 4020 – HIGHWAY NO 4010 PHUKET",
    "employer": "DEPARTMENT OF RURAL ROADS",
}, {
    "id" : 77, 
    "project": "ROAD MODIFICATION AT KARON RD PHUKET",
    "employer": "KARON MUNICIPALITY",
}, {
    "id" : 78, 
    "project": "ROAD AND INFRASTRUCTURE WORKS ,RETAINING WALL AT LAYAN HILL RESIDENCE",
    "employer": "LAND STATE CO,LTD",
}, {
    "id" : 79, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4024 CHALONG – RAWAI , PHUKET",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 80, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4021 NABORN JUNCTION, PHUKET",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 81, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4027 THARUAMUANGMAI INTERSECTION, PHUKET",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 82, 
    "project": "ROAD MODIFICATION HIGHWAY NO 402 KOK-KLOY JUNCTION – SARASIN INTERSECTION",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 83, 
    "project": "ROAD WORK, INFRASTRUCTURE WORK AND STONE WALL AT AMARI RESIDENCE, PHUKET",
    "employer": "ITALIAN THAI DEVELOPMENT PCL",
}, {
    "id" : 84, 
    "project": "BACKFILL AND COMPACT , LAMHIN , PHUKET",
    "employer": "IPS ASIA CO LTD",
},{
    "id" : 85, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4022 BAN KATU, PHUKET",
    "employer": "DEPARTMENT OF RURAL ROADS",
}, {
    "id" : 86, 
    "project": "INFRASTRUCTURE WORKS , YACHT HAVEN MARINA PROJECT",
    "employer": "PEARL MARINA CO,LTD",
}, {
    "id" : 87, 
    "project": "INFRASTRUCTURE CIVIL WORKS , LAGUNA PARK PHASE 1",
    "employer": "TWIN WATERS DEVELOPMENT CO,LTD",
}, {
    "id" : 88, 
    "project": "BOARDWALK, PLATFORM AND SEAWALL , YACHT HAVEN MARINA PROJECT",
    "employer": "PEARL MARINA CO,LTD",
}, {
    "id" : 89, 
    "project": "ROADWORKS AND INFRASTRUCTURE WORKS, BANGKOK HOSPTAL , SURATTHANI",
    "employer": "BANGKOK HOSPITAL SURATTHANI CO,LTD",
}, {
    "id" : 90, 
    "project": "TRAVEL LIFT CRANE TRACK AND BAY EXTENSION",
    "employer": "BOAT PATTANA CO,LTD",
}, {
    "id" : 91, 
    "project": "STONE WALL AT BANGYAI CANAL",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 92, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4031 MUDDOKKAO-PHUKET AIRPORT",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 93, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4027 THARUE-MUANGMAI",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 94, 
    "project": "ROAD MODIFICATION HIGHWAY NO 4027 BANPARA, PHUKET",
    "employer": "DEPARTMENT OF HIGHWAY",
}, {
    "id" : 95, 
    "project": "DRAINAGE AND ROAD MODIFICATION AT SAKDIDECH RD",
    "employer": "PHUKET MUNICIPALITY",
}, {
    "id" : 96, 
    "project": "BAN PAR KOH PIER, KOR KAO ISLAND, PHANG NGA",
    "employer": "PHANG NGA MUNICIPALITY",
}, {
    "id" : 97, 
    "project": "EXTERNAL WORK (INFRASTRUCTURE) LAGUNA PARK PHASE 2",
    "employer": "LAGUNA GRANDE LIMITED CO, LTD",
},
]