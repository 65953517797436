import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import MLWN03 from '../asset/img02/Cover03.jpg'
import LYH04 from '../asset/img02/LYH04.jpg'
import MLWN01 from '../asset/img02/MLWN01.jpg'
import Button from 'react-bootstrap/Button'
import { en } from "../data/en"
import { th } from "../data/th"
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      th
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });


export function Home() {
    const { t } = useTranslation();
    return (<div>
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100 h-5"
                    src={MLWN03}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3>{t('Welcome to')}</h3>
                    <h2>{t('Nuttaphong Tractor')}</h2>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={LYH04}
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h3>LAYAN HILL RESIDENCE</h3>
                    <p>{t('Road construction project comissioned by')}  LAND STATE CO.,LTD</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={MLWN01}
                    alt="Third slide"
                />

                <Carousel.Caption>
                    <h3>TIMBAR CRIB</h3>
                    <p>{t('Road construction project comissioned by')} MALAIWANA PARK DEVELOPEMENT</p> 
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        <CardGroup>
            <Card>
                <Card.Body>
                    <Card.Title>{t('About')}</Card.Title>
                    <Card.Text>
                    {t('Learnmoreaboutus!')}
                    </Card.Text>
                    <Button href="about">Learn more</Button>
                </Card.Body>

            </Card>
            <Card>
                <Card.Body>
                    <Card.Title>{t('History')}</Card.Title>
                    <Card.Text>
                    {t('Learn more about our history')}
                    </Card.Text>
                    <Button href="history">Learn more</Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Card.Title>{t('Project')}</Card.Title>
                    <Card.Text>
                        {t('List of our completed projects')}
                    </Card.Text>
                    <Button href="project">Learn more</Button>
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Card.Title>{t('Contact')}</Card.Title>
                    <Card.Text>
                        {t('How to contact us')}
                    </Card.Text>
                    <Button href="contact">Learn more</Button>
                </Card.Body>
            </Card>

        </CardGroup>
    </div>)
}