import React from "react";
import { Container } from 'react-bootstrap';
import RPM01 from '../asset/img02/RPM01.jpg'
import cover02 from '../asset/img02/cover02.jpg'
import Table from 'react-bootstrap/Table'
import { en } from "../data/en"
import { th } from "../data/th"
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      th
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });


export function About() {
  const { t } = useTranslation();
    return(<div>
        <img
            className="d-block w-100"
            src={cover02}
            alt="Third slide"
        />
<p>{t('Nuttaphong Tractor co, ltd has been delivering professional construction services across Thailand and the regions for over 30 years of experiences and over 200 executed projects, our company has been successful due to the professional managerial skills, experiences, great team works, and the technical knowledge of specialized staffs which pooled together have proven the successful results, we continue to grow our company focusing on the quality, continuous improvement, and career development of our staffs in order to produce best results for ourselves and our clients We offer a full range of preconstruction and construction services, we specialized in roadwork, bridges, infrastructure works, utility trenches, duct banks and marina works, our project teams maintain a strong foundation of trust and mutual respect generated though positive relationships with the clients, architects, consultant teams, subcontractors and suppliers, our company wide policy encouraging shared performance responsibilities ensures the highest degree of professional services and result on all projects undertaken')}</p>
<h3>{t('Our Professional staffs')}</h3>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>{t('First Name')}</th>
      <th>{t('Last Name')}</th>
      <th>{t('Position')}</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <td>{t('Nuttaphong')}</td>
      <td>{t('Visuthiphol')}</td>
      <td>{t('Managing Director')}</td>
    </tr>
    <tr>
      <td>{t('Oranuch')}</td>
      <td>{t('Thaiwutthiphong')}</td>
      <td>{t('Financial Manager')}</td>
    </tr>
    <tr>
      <td>{t('Vichai')}</td>
      <td>{t('Srisaeto')}</td>
      <td>{t('Operation manager')}</td>
    </tr>    
    <tr>
      <td>{t('Manop')}</td>
      <td>{t('Sanmaneechai')}</td>
      <td>{t('Senior engineer')}</td>
    </tr>
    <tr>
      <td>{t('Arkom')}</td>
      <td>{t('Srisadjathorn')}</td>
      <td>{t('Project manager')}</td>
    </tr>
    <tr>
      <td>{t('Chutimar')}</td>
      <td>{t('Visuthiphol')}</td>
      <td>{t('Purchasing Manager')}</td>
    </tr>    
    <tr>
      <td>{t('Auychai')}</td>
      <td>{t('Nameed')}</td>
      <td>{t('Factory manager')}</td>
    </tr>    
    <tr>
      <td>{t('Aranya')}</td>
      <td>{t('Teepasiri')}</td>
      <td>{t('Accounting Manager')}</td>
    </tr>    
  </tbody>
</Table>
</div>)
}
