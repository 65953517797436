import React from "react";
import { Container, Row, Col, Card, } from 'react-bootstrap';
import { data } from "../data/projects"
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import i18n from "i18next";
import YHV03 from '../asset/img02/YHV03.jpg'
import BLG01 from '../asset/img02/BLG01.jpg'
import KNY03 from '../asset/img02/KNY03.jpg'
import JT01 from '../asset/img02/JT01.jpg'
import Table from 'react-bootstrap/Table'
import CardDeck from 'react-bootstrap/CardDeck'
import { en } from "../data/en"
import { th } from "../data/th"
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      th
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });


function renderRow(project, t) {
  return (<tr>
    <td>{project.id}</td>
    <td>{t(project.project)}</td>
    <td>{t(project.employer)}</td>
  </tr>
  )
}

export function Project(prop) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language || window.localStorage.i18nextLng
return (<div>
<h1>{t('Highlighted projects')}</h1>
<CardDeck>
  <Card>
    <Card.Img variant="top" src={YHV03} />
    <Card.Body>
      <Card.Title>Yatch Heaven Project</Card.Title>
      <Card.Text>
      {t('The construction of boardwalk, platform and seawall for Phuket Yatch Heaven project comissioned by Royal Phuket Marina')}
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={KNY03} />
    <Card.Body>
      <Card.Title>Klong Nang Yon Dam Project</Card.Title>
      <Card.Text>
      {t('Build and improve on Klong Nang Yon dam in Phang Nga comissioned by the Royal Irrigation Department')}
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={BLG01} />
    <Card.Body>
      <Card.Title>Boat Lagoon</Card.Title>
      <Card.Text>
      {t('Trave lift crane track, ramp creation and bay extention for ships for Boat Lagoon Project')}
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={JT01} />
    <Card.Body>
      <Card.Title>Junction Improvement Highway NO.402</Card.Title>
      <Card.Text>
      {t('Junction Improvement on Highway 402, KOGKLOY to MAGPROK Km 3+560 to Km 4+000, to improve on safety')}
      </Card.Text>
    </Card.Body>
  </Card>
</CardDeck>

    <h1>{t('Our past experiences')}</h1>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>{t('No')}</th>
          <th>{t('Project Name')}</th>
          <th>{t('Employer')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(project => renderRow(project, t))}
      </tbody>
    </Table>
  </div>
  );
}
