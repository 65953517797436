import React from "react";
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { en } from "../data/en"
import { th } from "../data/th"
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
      th
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });



export function History() {
  const { t } = useTranslation();
    return(<Container>
          <h1 className="text-center mt-4">{t('Our History')}</h1>
        
          <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date="Present"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

  >
    <h3 className="vertical-timeline-element-title">{t('Nutthapong Tractor have over 120 of a variety of tools and machines for both rent and to be used in the construction')}</h3>
    <p>
    {t('With our capability, we have been given many projects from both domestically and internationally and we are also likely to expand over to the South-east Asia in the near future')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="2000"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

  >
    <h3 className="vertical-timeline-element-title">{t('Nutthapong Tractor co, ltd has opened a new branch in Phuket')}</h3>
    <p>
      {t('by working on improving Karon community, we acquired many constructions within Phuket province')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="1982"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">{t('Nutthapong Tractor co., ltd. started construction works')}</h3>
    <p>
    {t('We started working on the projects such as building the base of oil drill, entrance roads and provide help with the construction of Donmaung Tollway')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="1972"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    
  >
    <h3 className="vertical-timeline-element-title">{t('Nutthapong Tractor co, ltd was established')}</h3>
    <p>
    {t('with the authorized capital of 2,5 millions Baht, Nutthapong Tractor started with selling and renting machineries')}
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    
  />
</VerticalTimeline>
</Container>)
}